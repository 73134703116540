import React, { useCallback, useEffect, useRef, useState } from 'react';
import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import useIsTablet from '../../../utilities/customHooks/useIsTablet';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IInflatedDashboadPage } from '../../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { toast } from 'react-toastify';
import useIsDesktop from '../../../utilities/customHooks/useIsDesktop';
import { getFlocDetectorSensors } from '../../../services/dashboard/widget';

interface ChildSensor {
  dateCreated: string;
  isEnabled: boolean;
  parentSensor: string;
  plantId: string;
  sensorName: string;
  sensorNickName: string;
  sensorTag: string;
  _id: string;
}

interface flocDetectorSensorsDataInterface {
  childSensors: ChildSensor[];
  dateCreated: string;
  isEnabled: boolean;
  plantId: string;
  sensorName: string;
  sensorNickName: string;
  sensorTag: string;
  _id: string;
  sensorId: string;
}

export default function FlocConfigPopup({
  open,
  onClose,
  widget,
  pageData,
  setPageData,
  widgetClusterIndex,
  refresh,
  setRefresh,
  widgetIndex,
  onConfigComplete,
}: any) {
  const [flocDetectorSensorsData, setFlocDetectorSensorsData] = useState<any>([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);

  const isSaveButtonDisabled = () => {
    return !widget || !widget.specialTag || Object.keys(widget.metrics).length === 0;
  };

  const handleConfigSave = () => {
    onConfigComplete();
    onClose();
  };

  const handleConfigClose = () => {
    onClose();
  };

  useEffect(() => {
    const fetchFlocDetectorSensors = async () => {
      try {
        const specialTags = (await getFlocDetectorSensors()) || [];
        if (specialTags.length > 0) {
          setFlocDetectorSensorsData(specialTags);

          // Check for a matching sensorTag
          const matchingSensor = specialTags.find(
            (tag: any) => tag.sensorTag === widget.specialTag
          );

          if (matchingSensor) {
            setSelectedSensor(matchingSensor);
          }
        } else {
          toast.error('No Floc Detector Sensor Tags Available');
        }
      } catch (error) {
        console.error('Error fetching floc detector sensors data:', error);
      }
    };

    fetchFlocDetectorSensors();
  }, [widget.specialTag]);

  const changeWidgetProperty = (
    widgetClusterIndex: number,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: IInflatedDashboadPage['pageConfig'] = pageData;
    temp.data[widgetClusterIndex].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
  };

  const isNameIsProperlyFormatted = (name: string) => {
    if (name === '') return false;
    else return true;
  };
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const metricSearchBarResultsDivRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(String(widget.specialTag));
  const filteredsensors = flocDetectorSensorsData?.filter(
    (sensor: any) =>
      sensor.sensorTag.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sensor.sensorNickName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const nickNameRef = useRef<HTMLInputElement>(null);
  const transformSensorsData = (sensors: any[]) => {
    const transformedData: Record<string, any> = {};
    sensors.forEach(sensor => {
      transformedData[sensor.sensorName] = {
        checked: true,
        sensorId: sensor._id,
        dataType: 'number',
      };
    });
    return transformedData;
  };

  const handleClose = (flocDetectorSensors: flocDetectorSensorsDataInterface) => {
    if (flocDetectorSensors?.childSensors) {
      const { sensorNickName, sensorTag, childSensors, sensorId, _id } = flocDetectorSensors;
      const selectedTag = sensorTag || sensorNickName;
      widget.parentSensorId = sensorId || _id;
      widget.specialTag = selectedTag;
      setSearchQuery(selectedTag);

      widget.metrics =
        widget?.specialTag === selectedTag ? transformSensorsData(childSensors) : widget?.metrics;
    }
  };

  const filteredOptions = searchQuery.trim()
    ? filteredsensors?.filter((sensor: any) =>
        [sensor.sensorNickName, sensor.sensorTag].some(tag =>
          tag?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : filteredsensors;

  const handleCheckboxChange = useCallback(
    (widgetClusterIndex: string, widgetIndex: number, metric: string, checked: boolean) => {
      setPageData((prev: any) => {
        const updatedData = { ...prev };
        updatedData.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric].checked = checked;
        return updatedData;
      });
      setRefresh((prev: any) => !prev);
      setRefreshStandAloneWidget(prev => !prev);
    },
    []
  );

  return (
    <DialogBox
      open={open}
      handleClose={onClose}
      title="Floc Detector Configuration"
      minWidth="65%"
      TitlefontSize={'30px'}
    >
      <Grid
        container
        direction={{ isMobile: 'column' }}
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        justifyContent={!isMobile ? 'space-between' : ''}
        {...(isTablet
          ? {
              sx: {
                overflowX: 'auto',
                maxWidth: '100%',
                scrollbarWidth: 'none',
              },
            }
          : {})}
      >
        <Grid
          container
          xs={12}
          sm={4}
          md={4}
          lg={4}
          p={2}
          gap={3}
          mt={isMobile ? -2 : 0}
          sx={{ maxWidth: 'unset !important' }}
        >
          <Grid
            container
            borderRadius={1}
            ref={metricSearchBarResultsDivRef}
            width={isTablet ? '35vw' : '100%'}
          >
            <Grid container borderRadius={1} width="100%">
              <Autocomplete
                fullWidth
                freeSolo
                options={filteredOptions}
                getOptionLabel={(sensor: any) => sensor.sensorNickName || sensor.sensorTag || ''}
                value={selectedSensor}
                onInputChange={(event, newInputValue) => {
                  setSearchQuery(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setSelectedSensor(newValue); // Update selected sensor
                  setSearchQuery(''); // Reset search query
                  if (newValue) handleClose(newValue); // Pass selected sensor to parent callback
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Choose Special Sensor"
                    variant="outlined"
                    fullWidth
                    value={selectedSensor}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {searchQuery && (
                            <IconButton
                              onClick={() => {
                                setSearchQuery('');
                                setSelectedSensor(null);
                              }}
                              edge="end"
                            ></IconButton>
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props: any, sensor) => (
                  <Box
                    {...props}
                    p={1}
                    sx={{ cursor: 'pointer', paddingLeft: '12px', paddingRight: '12px' }}
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography variant="body2">
                        {sensor.sensorNickName || sensor.sensorTag}
                      </Typography>
                      {sensor.sensorNickName && (
                        <Tooltip title={sensor.sensorTag}>
                          <InfoOutlinedIcon sx={{ fontSize: 18 }} />
                        </Tooltip>
                      )}
                    </Stack>
                    <Divider />
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option.sensorTag === value?.sensorTag} // Ensures correct value matching
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            inputRef={nickNameRef}
            type="text"
            defaultValue={widget.widgetNickName ? widget.widgetNickName : ''}
            variant="outlined"
            label="Nick Name"
            multiline
            maxRows={1}
            inputProps={{
              maxLength: 50,
              step: '0.01',
            }}
            onBlur={e => {
              if (isNameIsProperlyFormatted(e.target.value)) {
                changeWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'widgetNickName',
                  e.target.value
                );
              } else {
                if (nickNameRef.current) {
                  toast.error('Nicknames cannot be an empty string!');
                  nickNameRef.current.value = widget.widgetNickName;
                  nickNameRef.current.focus();
                } else {
                  console.error('nickNameRef is null.');
                }
              }
            }}
          />
          <TextField
            fullWidth
            type="text"
            defaultValue={widget.widgetDescription}
            variant="outlined"
            label="Description"
            placeholder="Write a Description for Widget"
            multiline
            onBlur={e => {
              changeWidgetProperty(
                widgetClusterIndex,
                widgetIndex,
                'widgetDescription',
                e.target.value
              );
            }}
          />

          <TextField
            fullWidth
            type="text"
            value={widget?.moreInfo || ''}
            variant="outlined"
            label="Link"
            placeholder="add a link"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            onBlur={e => {
              changeWidgetProperty(widgetClusterIndex, widgetIndex, 'moreInfo', e.target.value);
            }}
          />
        </Grid>
        {!isMobile && <Divider orientation="vertical" flexItem />}
        <Grid
          item
          direction="column"
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            maxWidth: 'unset !important',
          }}
          mt={isMobile ? 0 : 1.8}
          gap={isMobile ? 2.5 : 2.8}
          ml={isMobile ? 2.2 : 0.5}
          pb={isMobile ? 2 : 0}
          mb={isDesktop ? 3 : 0}
        >
          <Typography
            variant="h4"
            sx={{ whiteSpace: 'nowrap', alignSelf: 'start', marginBottom: '30px' }}
          >
            Children Tags for Special Tag
          </Typography>

          {Object.keys(widget.metrics).map((metric, metricIndex) => {
            let metricName = widget.metrics[metric].nickName
              ? widget.metrics[metric].nickName
              : metric;
            return (
              <Stack direction="row" alignItems="center" key={metricIndex}>
                <Checkbox
                  checked={widget.metrics[metric].checked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleCheckboxChange(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      event.target.checked
                    );
                  }}
                />
                <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                  {metricName}
                </Typography>
              </Stack>
            );
          })}
        </Grid>
        {/*  */}
      </Grid>

      <DialogActions>
        <Stack direction="row" sx={{ width: '30%' }} gap={2}>
          {/* Cancel Button */}
          <Button
            onClick={handleConfigClose} // Keeping the existing functionality
            color="primary"
            variant="contained"
            sx={{
              background: '#fff',
              color: '#000',
              border: '1px solid #8B8B8B4D',
              '&:hover': {
                backgroundColor: '#f4f4f4', // Light gray background on hover
              },
            }}
            fullWidth
            disableElevation
          >
            Close
          </Button>

          {/* Save Button */}
          <Button
            onClick={handleConfigSave} // Keeping the existing functionality
            disabled={isSaveButtonDisabled()}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
            sx={{
              background: '#3271A9',
              '&:hover': {
                backgroundColor: isSaveButtonDisabled() ? '#3271A9' : '#285f8a', // Change hover color based on state
              },
              '&.Mui-disabled': {
                backgroundColor: '#a6c8e1', // Custom disabled color
                color: 'rgba(255, 255, 255, 0.5)', // Adjust text color for disabled state
              },
            }}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </DialogBox>
  );
}
