import {
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import styles from '../styles/VisualizationChartStyling';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClockIcon } from '@mui/x-date-pickers';
import GraphLineIconColored from '../../../assets/icons/GraphLineIconColored.svg';
import GraphLineIcon from '../../../assets/icons/GraphLineIcon.svg';
import SludgeVolumeIcon from '../../../assets/icons/SludgeVolumeIcon.svg';
import SludgeVolumeIconColored from '../../../assets/icons/SludgeVolumeIconColored.svg';
import SludgeIcon from '../../../assets/icons/SludgeIcon.svg';
import NoSludgeIcon from '../../../assets/icons/NoSludgeIcon.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DownloadIcon from '../../../assets/icons/DownloadCSV.svg';
import OpenNewTab from '../../../assets/icons/ExpandIcon.svg';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import minIcon30 from '../../../assets/icons/30minIcon.svg';
import minIcon60 from '../../../assets/icons/60minIcon.svg';
import minIcon90 from '../../../assets/icons/90minIcon.svg';
import flocDetectorDefaultSamplesData from '../../../constants/flocDetectorDefaultSamplesData';
import {
  handleNextSample,
  handlePreviousSample,
  handleSelectedGraphType,
} from '../../../services/Flocdetector/FlocVisualizationChartHelper';

const DesktopViewVisualizationHeader = (props: any) => {
  const {
    widgetData,
    isHovered,
    isDesktop,
    showClock,
    handleClockClick,
    formatTimeRange,
    widgetTimeFrame,
    barGraphData,
    selectedTypeGraph,
    flocData,
    currentDateIndex,
    currentSampleIndex,
    isMobile,
    currentData,
    currentSampleKey,
    formatTime,
    sampleKeys,
    currentSample,
    handleOnClickIssueIcon,
    issueIcon,
    setIssueIcon,
    setVisibleIcons,
    downloadCSV,
    getDayMonthYear,
    processData,
    downloadCSVForBarGraph,
    handleClickOpenExpandedView,
    handleEditClick,
    isTablet,
    visibleIcons,
    handleOnClickMinutesIcon,
    setHiddenLabels,
    hiddenLabels,
    setCurrentSampleIndex,
    setCurrentDateIndex,
    setSelectedTypeGraph,
    setOpenExpandedView,
  } = props;

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        gap={3}
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Grid item display="flex" gap={3} alignItems="flex-start">
          {/* Name and icon for description */}
          <Grid item display="flex" flexDirection="column" gap={1}>
            {/* Name and icon for description */}
            <Grid item display="flex" alignItems="center">
              <Typography
                variant="h4"
                color="#1A3356"
                fontWeight="600"
                fontSize="18px"
                sx={{ display: 'inline-flex', whiteSpace: 'nowrap' }} // Ensure text doesn't wrap
              >
                {widgetData?.widgetNickName || widgetData?.widgetName || 'Floc Detector'}
              </Typography>
              <Tooltip sx={styles.showIconStyle} title={widgetData?.widgetDescription}>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    color: '#80858CD9',
                    marginLeft: '10px !important',
                    ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                  }}
                />
              </Tooltip>
            </Grid>

            {/* Clock section */}
            {!isTablet && (
              <Grid item display="flex" alignItems="center" gap={1}>
                {showClock === 'on' ? (
                  <Stack direction="row" spacing={1}>
                    <Button
                      onClick={() => handleClockClick('disabled')}
                      sx={{
                        minWidth: 0,
                        padding: 0,
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(92, 184, 92, 0.1)', // Add a subtle hover effect
                        },
                      }}
                    >
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                          color: '#5cb85c',
                        }}
                      />
                    </Button>

                    <span
                      style={{
                        fontSize: '13px',
                        color: '#7D8188',
                        display: 'inline-flex',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatTimeRange(widgetTimeFrame)}
                    </span>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <Button
                      onClick={() => handleClockClick('on')}
                      sx={{
                        minWidth: 0,
                        padding: 0,
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)', // Subtle hover effect
                        },
                      }}
                    >
                      <ClockIcon
                        sx={{
                          width: '18px',
                          height: '18px',
                          cursor: 'pointer',
                        }}
                        color="disabled"
                      />
                    </Button>

                    <span
                      style={{
                        fontSize: '13px',
                        color: '#7D8188',
                        display: 'inline-flex',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatTimeRange(widgetTimeFrame)}
                    </span>
                  </Stack>
                )}
              </Grid>
            )}
          </Grid>

          {/* Graph type buttons */}
          <Grid item display="flex" alignItems="center" gap={3} mt={-1}>
            <Tooltip
              title="Sludge Value"
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                      { marginTop: '0px' },
                  },
                },
              }}
            >
              <Button
                onClick={() => handleSelectedGraphType('Sludge Value', setSelectedTypeGraph)}
                sx={{
                  padding: 0,
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                disabled={!(barGraphData && barGraphData.length > 0)}
              >
                <img
                  src={
                    selectedTypeGraph === 'Sludge Value' && barGraphData && barGraphData.length > 0
                      ? SludgeVolumeIconColored
                      : SludgeVolumeIcon
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>

            <Tooltip
              title="Sludge Settling Pattern"
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                      { marginTop: '0px' },
                  },
                },
              }}
            >
              <Button
                onClick={() =>
                  handleSelectedGraphType('Sludge Settling Pattern', setSelectedTypeGraph)
                }
                sx={{
                  padding: 0,
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                disabled={!(flocData && flocData.length > 0)}
              >
                <img
                  src={
                    selectedTypeGraph === 'Sludge Settling Pattern' &&
                    flocData &&
                    flocData.length > 0
                      ? GraphLineIconColored
                      : GraphLineIcon
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Date and sample navigation */}
        {selectedTypeGraph === 'Sludge Settling Pattern' && flocData && flocData.length > 0 && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              marginRight: '5%',
              whiteSpace: 'nowrap', // Prevent wrapping
              overflow: 'hidden', // Hide7 overflow content
              textOverflow: 'ellipsis', // Add ellipsis for overflowing text
            }}
          >
            <Grid item>
              <IconButton
                onClick={() =>
                  handlePreviousSample(
                    currentSampleIndex,
                    currentDateIndex,
                    flocData,
                    setCurrentSampleIndex,
                    setCurrentDateIndex
                  )
                }
                disabled={currentDateIndex === 0 && currentSampleIndex === 0}
                sx={{
                  fontSize: isMobile ? '0.7rem' : '0.8rem',
                  padding: '4px',
                  minWidth: 'auto', // Prevent button width expansion
                }}
              >
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
            </Grid>

            <Grid item textAlign="center" sx={{ overflow: 'hidden' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: isMobile ? '0.55rem' : '0.65rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#7D8188',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <AccessTimeIcon fontSize="small" sx={{ marginRight: '4px', color: '#80858CD9' }} />
                {new Date(currentData?.date)
                  .toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
                  .replace(',', '')}
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  fontSize: isMobile ? '0.5rem' : '0.6rem',
                  color: '#7D8188',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {currentSampleKey} ({formatTime(currentSample?.startTime)} -{' '}
                {formatTime(currentSample?.endTime)})
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  fontSize: isMobile ? '0.4rem' : '0.5rem',
                  color: '#7D8188',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {`${currentSampleIndex + 1} / ${sampleKeys.length}`}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                onClick={() => {
                  handleNextSample(
                    currentSampleIndex,
                    sampleKeys,
                    currentDateIndex,
                    flocData,
                    setCurrentSampleIndex,
                    setCurrentDateIndex
                  );
                }}
                disabled={
                  currentDateIndex === flocData?.length - 1 &&
                  currentSampleIndex === sampleKeys.length - 1
                }
                sx={{
                  fontSize: isMobile ? '0.7rem' : '0.8rem',
                  padding: '4px',
                  minWidth: 'auto',
                }}
              >
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )}

        {/* THIS IS WHERE WE WILL ADD OUR SAMPLE LOGIC AND REMOVE THE SHIFT LOGIC  */}

        {selectedTypeGraph === 'Sludge Value' && (
          <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={3}>
            <Grid item display="flex" gap={3}>
              <Grid
                item
                display="flex"
                onClick={() => {
                  handleOnClickIssueIcon(
                    'falseDetectionIcon',
                    selectedTypeGraph,
                    setIssueIcon,
                    setVisibleIcons
                  );
                }}
                sx={{ cursor: 'pointer' }}
                alignItems="center"
              >
                <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
                <Typography
                  color="#5C6066"
                  fontSize="13px"
                  whiteSpace="nowrap"
                  style={issueIcon?.falseDetectionIcon ? { textDecoration: 'line-through' } : {}}
                >
                  False Detection
                </Typography>
              </Grid>
              <Grid
                item
                display="flex"
                gap={1}
                onClick={() => {
                  handleOnClickIssueIcon(
                    'floatingSludgeIcon',
                    selectedTypeGraph,
                    setIssueIcon,
                    setVisibleIcons
                  );
                }}
                sx={{ cursor: 'pointer' }}
                alignItems="center"
              >
                <img src={SludgeIcon} />
                <Typography
                  color="#5C6066"
                  fontSize="13px"
                  whiteSpace="nowrap"
                  style={issueIcon?.floatingSludgeIcon ? { textDecoration: 'line-through' } : {}}
                >
                  Floating Sludge
                </Typography>
              </Grid>
              <Grid
                item
                display="flex"
                gap={1}
                onClick={() => {
                  handleOnClickIssueIcon(
                    'noSludgeIcon',
                    selectedTypeGraph,
                    setIssueIcon,
                    setVisibleIcons
                  );
                }}
                sx={{ cursor: 'pointer' }}
                alignItems="center"
              >
                <img src={NoSludgeIcon} />
                <Typography
                  color="#5C6066"
                  fontSize="13px"
                  whiteSpace="nowrap"
                  style={issueIcon?.noSludgeIcon ? { textDecoration: 'line-through' } : {}}
                >
                  No Sludge
                </Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={3}>
              <Tooltip title="Download CSV">
                <Button
                  onClick={() => {
                    if (
                      selectedTypeGraph === 'Sludge Settling Pattern' &&
                      flocData &&
                      flocData.length > 0
                    ) {
                      downloadCSV(
                        flocData[currentDateIndex]?.date, // Use the current date instead of shiftName
                        getDayMonthYear(flocData[currentDateIndex]?.date), // Format the date as Day/Month/Year
                        processData(flocData[currentDateIndex]?.samples[currentSampleKey]) // Process data for the selected sample
                      );
                    } else if (
                      selectedTypeGraph === 'Sludge Value' &&
                      barGraphData &&
                      barGraphData.length > 0
                    ) {
                      // ##################################CHNAGE THIS ASAP############################################
                      downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                    }
                  }}
                  sx={{
                    padding: 0,
                    minWidth: 'auto',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <img
                    src={DownloadIcon}
                    style={{
                      cursor: 'pointer',
                      ...(isHovered ? styles?.visibleComponent : styles.hiddenComponent),
                    }}
                  />
                </Button>
              </Tooltip>

              <Tooltip title="Expanded View">
                <Button
                  onClick={() => handleClickOpenExpandedView(setOpenExpandedView)}
                  sx={{
                    padding: 0,
                    minWidth: 'auto',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <img
                    src={OpenNewTab}
                    style={{
                      cursor: 'pointer',
                      ...(isHovered ? styles?.visibleComponent : styles?.hiddenComponent),
                    }}
                  />
                </Button>
              </Tooltip>

              {/* in case of  BAR GRAPH*/}

              <Tooltip title="Edit Widget">
                <ModeEditOutlineIcon
                  fontSize="small"
                  sx={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    color: '#80858CD9',
                    ...(isHovered ? styles?.visibleComponent : styles?.hiddenComponent),
                  }}
                  onClick={() => {
                    handleEditClick('flocDetector');
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
        {selectedTypeGraph === 'Sludge Settling Pattern' && (
          <Grid item display="flex" alignItems="center" gap={3} mt={isTablet ? '5px' : '0px'}>
            <Tooltip title="Download CSV">
              <Button
                onClick={() => {
                  if (flocData?.length > 0) {
                    // Only trigger download if flocData is not empty
                    if (
                      selectedTypeGraph === 'Sludge Settling Pattern' &&
                      flocData &&
                      flocData.length > 0
                    ) {
                      downloadCSV(
                        flocData[currentDateIndex]?.date, // Use the current date instead of shiftName
                        getDayMonthYear(flocData[currentDateIndex]?.date), // Format the date as Day/Month/Year
                        processData(flocData[currentDateIndex]?.samples[currentSampleKey]) // Process data for the selected sample
                      );
                    } else if (
                      selectedTypeGraph === 'Sludge Value' &&
                      barGraphData &&
                      barGraphData.length > 0
                    ) {
                      downloadCSVForBarGraph(flocDetectorDefaultSamplesData);
                    }
                  }
                }}
                disabled={flocData?.length === 0}
                sx={{
                  padding: 0,
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <img
                  src={DownloadIcon}
                  style={{
                    cursor: flocData?.length === 0 ? 'not-allowed' : 'pointer',
                    ...(isHovered && flocData?.length > 0
                      ? styles?.visibleComponent
                      : styles.hiddenComponent),
                    opacity: flocData?.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                  }}
                />
              </Button>
            </Tooltip>

            <Tooltip title="Expanded View">
              <Button
                onClick={() => {
                  if (flocData?.length > 0) {
                    handleClickOpenExpandedView(setOpenExpandedView);
                  }
                }}
                disabled={flocData?.length === 0}
                sx={{
                  padding: 0,
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <img
                  src={OpenNewTab}
                  style={{
                    cursor: flocData?.length === 0 ? 'not-allowed' : 'pointer',
                    ...(isHovered && flocData?.length > 0
                      ? styles?.visibleComponent
                      : styles.hiddenComponent),
                    opacity: flocData?.length === 0 ? 0.5 : 1, // Reduce opacity to indicate disabled state
                  }}
                />
              </Button>
            </Tooltip>

            {/* in case of  linechart*/}
            <Tooltip title="Edit Widget">
              <ModeEditOutlineIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: '#80858CD9',
                  ...(isHovered ? styles.visibleComponent : styles.hiddenComponent),
                }}
                onClick={() => {
                  handleEditClick('flocDetector');
                }}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {/* THIS IS THE BLOCK TO KEEP THE FALSE FLOATING NOSLUDGE ICON AND TEXT IN LINEgRAPH  START*/}
      {selectedTypeGraph === 'Sludge Settling Pattern' && flocData && flocData.length > 0 && (
        <Grid
          item
          display="flex"
          justifyContent={isTablet ? 'space-between' : 'flex-end'}
          alignItems="center"
          gap={3}
          mt={isDesktop ? -1 : 0}
        >
          {isTablet && (
            <Grid item display="flex" gap={1}>
              {showClock == 'on' && (
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={() => handleClockClick('disabled')}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Subtle hover effect
                      },
                    }}
                  >
                    <ClockIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#5cb85c',
                      }}
                    />
                  </Button>

                  <span style={{ fontSize: '13px', color: '#7D8188' }}>
                    {formatTimeRange(widgetTimeFrame)}
                  </span>
                </Stack>
              )}
              {showClock === 'disabled' && (
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={() => handleClockClick('on')}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Subtle hover effect
                      },
                    }}
                  >
                    <ClockIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#5cb85c',
                      }}
                    />
                  </Button>

                  <span style={{ fontSize: '13px', color: '#7D8188' }}>
                    {formatTimeRange(widgetTimeFrame)}
                  </span>
                </Stack>
              )}
            </Grid>
          )}
          <Grid display="flex" item gap={3} mt={1}>
            <Grid
              item
              display="flex"
              onClick={() => {
                handleOnClickIssueIcon(
                  'falseDetection',
                  selectedTypeGraph,
                  setIssueIcon,
                  setVisibleIcons
                );
              }}
              sx={{ cursor: 'pointer' }}
              alignItems="center"
            >
              <QuestionMarkIcon fontSize="small" sx={{ color: '#EBCDAD' }} />
              <Typography
                color="#5C6066"
                fontSize="13px"
                whiteSpace="nowrap"
                style={visibleIcons.falseDetection ? { textDecoration: 'line-through' } : {}}
              >
                False Detection
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              gap={1}
              onClick={() => {
                handleOnClickIssueIcon(
                  'issueIcon',
                  selectedTypeGraph,
                  setIssueIcon,
                  setVisibleIcons
                );
              }}
              sx={{ cursor: 'pointer' }}
              alignItems="center"
            >
              <img src={SludgeIcon} />
              <Typography
                color="#5C6066"
                fontSize="13px"
                whiteSpace="nowrap"
                style={visibleIcons.issueIcon ? { textDecoration: 'line-through' } : {}}
              >
                Floating Sludge
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              gap={1}
              onClick={() => {
                handleOnClickIssueIcon(
                  'noSludge',
                  selectedTypeGraph,
                  setIssueIcon,
                  setVisibleIcons
                );
              }}
              sx={{ cursor: 'pointer' }}
              alignItems="center"
            >
              <img src={NoSludgeIcon} />
              <Typography
                color="#5C6066"
                fontSize="13px"
                whiteSpace="nowrap"
                style={visibleIcons.noSludge ? { textDecoration: 'line-through' } : {}}
              >
                No Sludge
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* THIS IS THE BLOCK TO KEEP THE FALSE FLOATING NOSLUDGE ICON AND TEXT IN LINEgRAPH END*/}

      {/* THIS IS TO KEEPP THE 30 , 60 , 90 MIN ICON AND TEXT IN BARGRAPH START */}
      {selectedTypeGraph === 'Sludge Value' && barGraphData && barGraphData.length > 0 && (
        <Grid
          item
          display="flex"
          justifyContent={isTablet ? 'space-between' : 'flex-end'}
          alignItems="center"
          gap={3}
          mt={isDesktop ? -3 : 0}
        >
          {isTablet && (
            <Grid item display="flex" gap={1}>
              {showClock == 'on' && (
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={() => handleClockClick('disabled')}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Subtle hover effect
                      },
                    }}
                  >
                    <ClockIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#5cb85c',
                      }}
                    />
                  </Button>

                  <span style={{ fontSize: '13px', color: '#7D8188' }}>
                    {formatTimeRange(widgetTimeFrame)}
                  </span>
                </Stack>
              )}
              {showClock === 'disabled' && (
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={() => handleClockClick('on')}
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Subtle hover effect
                      },
                    }}
                  >
                    <ClockIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        color: '#5cb85c',
                      }}
                    />
                  </Button>

                  <span style={{ fontSize: '13px', color: '#7D8188' }}>
                    {formatTimeRange(widgetTimeFrame)}
                  </span>
                </Stack>
              )}
            </Grid>
          )}
          <Grid item display="flex" gap={3} sx={{ mt: '10px' }}>
            <Grid
              item
              display="flex"
              gap={1}
              onClick={() => {
                handleOnClickMinutesIcon('30 minutes', setHiddenLabels);
              }}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <img
                src={minIcon30}
                style={{
                  height: '14px',
                  width: '14px',
                }}
              />
              <Typography
                color="#5C6066"
                fontSize="12px"
                whiteSpace="nowrap"
                style={
                  hiddenLabels['30 minutes']
                    ? {
                        textDecoration: 'line-through',
                      }
                    : {}
                }
              >
                30 Min
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              gap={1}
              onClick={() => {
                handleOnClickMinutesIcon('60 minutes', setHiddenLabels);
              }}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <img src={minIcon60} style={{ height: '14px', width: '14px' }} />
              <Typography
                color="#5C6066"
                fontSize="12px"
                whiteSpace="nowrap"
                style={hiddenLabels['60 minutes'] ? { textDecoration: 'line-through' } : {}}
              >
                60 Min
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              gap={1}
              onClick={() => {
                handleOnClickMinutesIcon('90 minutes', setHiddenLabels);
              }}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <img src={minIcon90} style={{ height: '14px', width: '14px' }} />
              <Typography
                color="#5C6066"
                fontSize="12px"
                whiteSpace="nowrap"
                style={hiddenLabels['90 minutes'] ? { textDecoration: 'line-through' } : {}}
              >
                90 Min
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* THIS IS TO KEEPP THE 30 , 60 , 90 MIN ICON AND TEXT IN BARGRAPH END */}
    </>
  );
};
export default DesktopViewVisualizationHeader;
